<template>
  <v-sheet class="mt-6 py-4 px-8">
    <v-skeleton-loader
      v-if="isLoading"
      type="article"
    ></v-skeleton-loader>
    <common-error
      v-else-if="isError"
      @fetchData="fetchTemplateNotifications"
    />
    <v-row v-else-if="notificationsList.length">
      <v-col cols="12">
        <h4>Allow Notification</h4>
      </v-col>
      <v-col v-for="(n, i) in notificationsList" :key="i" cols="12" sm="6" md="4">
        <v-checkbox
          style="font-size: 2px"
          v-model="n.checked"
          hide-details
        >
          <template v-slot:label>
            <span class="text-caption">{{n.title}}</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          @click="onSave"
          :loading="submiting"
          color="primary"
        >
          {{this.$_strings.common.SAVE}}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'checkbox-notifications',
  created() {
    this.fetchTemplateNotifications();
  },
  data() {
    return {
      submiting: false,
      isLoading: false,
      isError: false,
      notificationsList: [],
    };
  },
  computed: {
    companyUserId() {
      const dataUser = this.$store.state.user.myUserInfo;
      return dataUser.companyUserId;
    },
    roleId() {
      const dataUser = this.$store.state.user.myUserInfo;
      return dataUser.roleId;
    },
  },
  methods: {
    async fetchTemplateNotifications() {
      this.isLoading = true;
      this.isError = false;
      try {
        const result = await this.$_services.notifications.templateNotifications(this.roleId);
        this.notificationsList = result.data.map((d) => ({
          title: d.templateData.title.replace(/\[KRG\]/g, 'Kargo').replace(/\[PES\]/g, 'Pesanan'),
          code: d.code,
          checked: false,
        }));
        this.fetchNotificationsAllowed();
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchNotificationsAllowed() {
      this.isLoading = true;
      this.isError = false;
      try {
        const result = await this.$_services.notifications.listNotificationsAllowed(this.companyUserId);
        this.notificationsList = this.notificationsList.map((n) => {
          const findObj = result.data.find((r) => r.notificationCode === n.code);
          if (findObj) {
            return {
              ...n,
              id: findObj.id,
              checked: findObj.allowNotification,
            };
          }
          return n;
        });
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async onSave() {
      try {
        this.submiting = true;
        const form = this.notificationsList.map((n) => {
          if (n.id) {
            return {
              id: n.id,
              notificationCode: n.code,
              allowNotification: n.checked,
            };
          }
          return {
            notificationCode: n.code,
            allowNotification: n.checked,
          };
        });
        await this.$_services.notifications.updateNotifcationsAllowed({ companyUserId: this.companyUserId, form });
        this.$dialog.notify.success('Berhasil memperbarui Allow Notification');
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>
